<script setup lang="ts">
interface Props {
  modelValue?: boolean
  lock?: boolean
  isSummaryCenter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  lock: false,
  modelValue: false,
  isSummaryCenter: false,
})

const emits = defineEmits<{
  'update:model-value': [boolean]
}>()

const open = ref(props.modelValue)
const refEl = ref<HTMLDetailsElement>()

watch(() => props.modelValue, (val) => {
  if (!val) {
    refEl.value?.removeAttribute('open')
  }
})

const onToggle = () => {
  if (!refEl.value) {
    return
  }
  open.value = refEl.value.hasAttribute('open')
  emits('update:model-value', toValue(open))
}
</script>

<template>
  <details
    ref="refEl"
    @toggle="onToggle"
  >
    <summary
      :class="{ 'cursor-pointer': !props.lock, 'items-center': props.isSummaryCenter }"
      class="group flex justify-between py-2 sm:py-3"
    >
      <div class="grow">
        <slot name="summary" />
      </div>

      <div>
        <slot
          name="marker"
          :open="open"
        >
          <span class="btn btn-small btn-secondary group-hover:bg-pink-100">
            {{ open ? $t('commons.actions.close') : $t('commons.actions.update') }}
          </span>
        </slot>
      </div>
    </summary>

    <template v-if="!props.lock">
      <slot />
    </template>
  </details>
</template>

<style scoped>
summary::marker{
  content: none;
}

details > summary::-webkit-details-marker {
  display: none;
}
</style>
